import {
  Chip,
  Menu,
  MenuItem,
  Box,
  AppBar,
  Toolbar,
  Button,
  Typography,
  Link,
  Tabs,
  Tab,
} from "@mui/material";
import { useState } from "react";
import { Group, DashboardCustomize } from "@mui/icons-material";

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}
const Header = ({ currentUrl }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box>
      <AppBar position="fixed" sx={{ boxShadow: 0 }}>
        <Toolbar>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{
              marginRight: 2,
            }}
          >
            <Link
              href="/"
              sx={{
                display: {
                  xs: "none",
                  sm: "block",
                  fontWeight: "bold",
                  color: "white",
                  textDecoration: "none",
                },
              }}
            >
              ChatbotApp
            </Link>
          </Typography>

          <Box
            component="div"
            noWrap
            sx={{
              flexGrow: 1,
            }}
          >
            {currentUrl == "/analytics" ||
            currentUrl == "/users" ||
            currentUrl.indexOf("/graph") > -1 ? (
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="nav tabs example"
                indicatorColor="secondary"
                textColor="inherit"
                sx={{ height: "4em" }}
              >
                <LinkTab
                  icon={<DashboardCustomize />}
                  iconPosition="start"
                  label="Dashboard"
                  href="/analytics"
                  disableRipple={true}
                />
                <LinkTab
                  icon={<Group />}
                  iconPosition="start"
                  label="Users"
                  href="/users"
                  disableRipple={true}
                />
              </Tabs>
            ) : (
              <></>
            )}
          </Box>

          {window.localStorage.getItem("token") ? (
            <Box component="nav">
              <Chip
                label={window.localStorage.getItem("username")}
                variant="outlined"
                sx={{ color: "white", fontSize: "1rem" }}
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              />
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={handleClose}>
                  Organization : {localStorage.getItem("organization")}
                </MenuItem>
              </Menu>

              <Button
                sx={{ color: "white", fontWeight: "bold" }}
                onClick={function () {
                  window.localStorage.clear();
                  window.location.replace("/");
                }}
              >
                Logout
              </Button>
            </Box>
          ) : (
            <Box component="nav">
              <Button
                sx={{ color: "white", fontWeight: "bold" }}
                onClick={function () {
                  window.location.replace("/");
                }}
              >
                SIGN IN
              </Button>
            </Box>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
};
export default Header;
