import React, { useEffect, useState } from "react";
import Plot from "react-plotly.js";
import config from "../../../config";
import {
  Breadcrumbs,
  Link,
  Typography,
  CircularProgress,
  TextField,
  CssBaseline,
  Container,
  Box,
  Avatar,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import UserTable from "./usertable";
export default function Graph({ type }) {
  const [title, setTitle] = useState("");
  const [graphData, setGraphData] = useState({});
  const [loading, setLoading] = useState(false);
  const currentTime = moment();
  var oneMonthAgo = moment().subtract(1, "month");
  const [startDate, setStartDate] = useState(oneMonthAgo);
  const [endDate, setEndDate] = useState(currentTime);
  const [users, setUsers] = useState([]);
  const [range, setRange] = React.useState("");

  const handleChange = (event) => {
    setRange(event.target.value);
    let d = moment().subtract(event.target.value, "day");
    setStartDate(d);
    let e = moment();
    setEndDate(e);
  };

  useEffect(() => {
    getGraphData();
  }, [startDate, endDate]);
  useEffect(() => {
    getUsers();
  }, []);
  const getGraphData = async () => {
    setLoading(true);
    let url = `${config.REMOTE_COORD}/analytics/graph/${type}`;
    let configId = localStorage.getItem("configID");

    try {
      let data = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          configId: configId,
          startDate: startDate.unix(),
          endDate: endDate.unix(),
        }),
      });
      let { title, graphData } = await data.json();

      setTitle(title);
      setGraphData(graphData);
    } catch (error) {
      console.log(error);
    }
  };
  const getUsers = async () => {
    let url = `${config.REMOTE_COORD}/analytics/details/users`;
    let configId = localStorage.getItem("configID");

    try {
      let data = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          configId: configId,
        }),
      });
      let { users } = await data.json();
      setUsers(users);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <Container
      disableGutters
      maxWidth="xxl"
      sx={{ marginTop: "-1em", height: "100%", width: "92vw" }}
    >
      <CssBaseline />
      <Breadcrumbs aria-label="breadcrumb" mb={5}>
        <Link underline="hover" color="inherit" href="/">
          Home
        </Link>
        <Link underline="hover" color="inherit" href="/analytics">
          Dashboard
        </Link>
        <Typography color="text.primary">{title}</Typography>
      </Breadcrumbs>
      {users[0] ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              bgcolor: "background.paper",
              border: 1,
              borderColor: "grey.300",
            }}
            p={2}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "left",
              }}
            >
              <Avatar sx={{ bgcolor: "primary.main" }}>
                <FilterAltIcon />
              </Avatar>
              <Typography
                component="h1"
                variant="h5"
                color="text.secondary"
                ml={2}
                sx={{ fontSize: "2rem", fontWeight: "bold" }}
              >
                Filter
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "space-between",
              }}
            >
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label="Start Date"
                  value={startDate}
                  inputFormat="DD/MM/yyyy"
                  onChange={(newValue) => {
                    setStartDate(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
                <Typography mt={2} ml={2} mr={2} sx={{ fontWeight: "thin" }}>
                  to
                </Typography>
                <DatePicker
                  label="End Date"
                  value={endDate}
                  inputFormat="DD/MM/yyyy"
                  onChange={(newValue) => {
                    setEndDate(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
                <Box sx={{ width: 320, ml: 2 }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Custom Range
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={range}
                      label="daterange"
                      onChange={handleChange}
                    >
                      <MenuItem value={1}>1 Day</MenuItem>
                      <MenuItem value={7}>7 Day</MenuItem>
                      <MenuItem value={14}>14 Day</MenuItem>
                      <MenuItem value={30}>30 Day</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </LocalizationProvider>
            </Box>
          </Box>

          {Object.keys(graphData)[0] ? (
            <Box
              sx={{
                marginTop: "2em",
                border: 1,
                borderColor: "grey.300",
              }}
            >
              <Plot
                data={[
                  {
                    x: Object.keys(graphData),
                    y: Object.values(graphData),
                    type: "scatter",
                    line: { shape: "linear" },
                    marker: {
                      size: 17,
                    },
                  },
                ]}
                layout={{
                  width: 1762,
                  height: 600,
                  autosize: true,
                  title: title,
                  xaxis: { showgrid: false },
                }}
                config={{ responsive: true }}
              />
            </Box>
          ) : loading ? (
            <CircularProgress />
          ) : (
            <Alert
              severity="error"
              sx={{ fontSize: "1rem", fontWeight: "bold" }}
            >
              {" "}
              No Results!
            </Alert>
          )}

          <Box
            sx={{
              marginTop: "2em",
              border: 1,
              borderColor: "grey.300",
              marginBottom: "5em",
            }}
          >
            <UserTable users={users} />
          </Box>
        </Box>
      ) : loading ? (
        <CircularProgress />
      ) : (
        <Alert severity="error" sx={{ fontSize: "1rem", fontWeight: "bold" }}>
          {" "}
          No data for analyitcs!
        </Alert>
      )}
    </Container>
  );
}
