let config = {
  DOCKSPEECH_BASE: "https://chatwidget-cvxm23.apps.hexmos.com",
  DOCK_TRANSCRIPTION: "transcription",
  ANALYTICS_URL_LOCAL: " http://127.0.0.1:8000/analytics/",
  ANALYTICS_URL: "https://coordinator.chatbotapp.click/analytics/",
  LOCAL_COORD: "http://localhost:8000",
  REMOTE_COORD: "https://coordinator.chatbotapp.click"
};

export default config;
