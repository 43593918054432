import React, { useEffect, useState } from "react";
import Plot from "react-plotly.js";
import config from "../../../config";
import {
  Typography,
  TextField,
  CssBaseline,
  Container,
  Box,
  Avatar,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

export default function Graph() {
  const [activeUsers, setActiveUsers] = useState({});
  const [newUsers, setNewUsers] = useState({});
  const [returningUsers, setReturningUsers] = useState({});
  const [activeTooltip, setActiveTooltip] = useState([]);
  const [newTooltip, setNewTooltip] = useState([]);
  const [returningTooltip, setReturningTooltip] = useState([]);
  const [errorMessage, setErrorMessage] = useState(false);
  const currentTime = moment();
  var halfMonth = moment().subtract(15, "days");
  const [startDate, setStartDate] = useState(halfMonth);
  const [endDate, setEndDate] = useState(currentTime);
  const [range, setRange] = useState("");

  useEffect(() => {
    if (startDate <= endDate) getGraphData();
    else setErrorMessage(true);
  }, [startDate, endDate]);

  const handleChange = (event) => {
    setRange(event.target.value);
    let d = moment().subtract(event.target.value, "day");
    setStartDate(d);
    let e = moment();
    setEndDate(e);
  };

  const handleClose = () => {
    setErrorMessage(false);
  };
  const getGraphData = async () => {
    let url = `${config.REMOTE_COORD}/analytics/graph/users-insights`;
    let configId = localStorage.getItem("configID");

    try {
      let data = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          configId: configId,
          startDate: startDate.unix(),
          endDate: endDate.unix(),
        }),
      });
      let { active_users, new_users, returning_users } = await data.json();

      setActiveUsers(active_users);
      setNewUsers(new_users);
      setReturningUsers(returning_users);
      let arr = [];
      for (const i in Object.keys(active_users)) {
        arr.push(
          `Active users on ${Object.keys(active_users)[i]} : ${
            Object.values(active_users)[i]
          } `
        );
      }
      setActiveTooltip(arr);
      let new_arr = [];
      for (const j in Object.keys(new_users)) {
        new_arr.push(
          `New users on ${Object.keys(new_users)[j]} : ${
            Object.values(new_users)[j]
          } `
        );
      }
      setNewTooltip(new_arr);
      let return_arr = [];
      for (const j in Object.keys(returning_users)) {
        return_arr.push(
          `Returning users on ${Object.keys(returning_users)[j]} : ${
            Object.values(returning_users)[j]
          } `
        );
      }
      setReturningTooltip(return_arr);
    } catch (error) {
      alert(error);
    }
  };

  var trace1 = {
    x: Object.keys(activeUsers),
    y: Object.values(activeUsers),
    name: "Active Users",
    fill: "toself",
    type: "scatter",
    hovertext: activeTooltip,
    hoverinfo: "text",
    marker: {
      size: 7,
    },
  };

  var trace2 = {
    x: Object.keys(newUsers),
    y: Object.values(newUsers),
    name: "New Users",
    fill: "toself",
    type: "scatter",
    hovertext: newTooltip,
    hoverinfo: "text",
    marker: {
      size: 7,
    },
  };
  var trace3 = {
    x: Object.keys(returningUsers),
    y: Object.values(returningUsers),
    name: "Returning Users",
    fill: "toself",
    type: "scatter",
    hovertext: returningTooltip,
    hoverinfo: "text",
    marker: {
      size: 7,
    },
  };
  var data = [trace1, trace2, trace3];

  return (
    <Container
      disableGutters
      maxWidth="xxl"
      sx={{ height: "100%", width: "92vw" }}
    >
      <CssBaseline />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            bgcolor: "background.paper",
            border: 1,
            borderColor: "grey.300",
          }}
          p={2}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "left",
            }}
          >
            <Avatar sx={{ bgcolor: "primary.main" }}>
              <FilterAltIcon />
            </Avatar>
            <Typography
              component="h1"
              variant="h5"
              color="text.secondary"
              ml={2}
              sx={{ fontSize: "2rem", fontWeight: "bold" }}
            >
              Filter
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "space-between",
            }}
          >
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label="Start Date"
                value={startDate}
                inputFormat="DD/MM/yyyy"
                onChange={(newValue) => {
                  setStartDate(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
              <Typography mt={2} ml={2} mr={2} sx={{ fontWeight: "thin" }}>
                to
              </Typography>
              <DatePicker
                label="End Date"
                value={endDate}
                inputFormat="DD/MM/yyyy"
                onChange={(newValue) => {
                  setEndDate(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
              <Box sx={{ width: 320, ml: 2 }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Custom Range
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={range}
                    label="daterange"
                    onChange={handleChange}
                  >
                    <MenuItem value={1}>1 Day</MenuItem>
                    <MenuItem value={7}>7 Day</MenuItem>
                    <MenuItem value={14}>14 Day</MenuItem>
                    <MenuItem value={30}>30 Day</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </LocalizationProvider>
          </Box>
        </Box>
        <Box
          sx={{
            marginTop: "2em",
            border: 1,
            borderColor: "grey.300",
          }}
        >
          {
            <Plot
              data={data}
              layout={{
                width: 1762,
                height: 600,
                autosize: true,
                title: "Insights",
                xaxis: { showgrid: false },
              }}
              config={{ responsive: true }}
            />
          }
        </Box>
      </Box>
      <Dialog
        open={errorMessage}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Select an End Date greater than Start Date
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>OK</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
