import { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  Box,
  TextField,
  Button,
  Divider,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Input,
  CircularProgress,
  Breadcrumbs,
  Typography,
  Link,
  CssBaseline,
  Container,
} from "@mui/material";
import style from "./style.css";
import config from "../../../config";
import { route } from "preact-router";

export default function EditConfiguration() {
  let data = localStorage.getItem("configuration");
  let configurationData = JSON.parse(data);
  var orgId = localStorage.getItem("orgId");

  const [showPreview, setShowPreview] = useState(configurationData.preview);
  const [widgetName, setWidgetName] = useState(configurationData.widgetName);
  const [widgetSubtitle, setWidgetSubtitle] = useState(
    configurationData.widgetSubtitle
  );
  const [primaryColor, setPrimaryColor] = useState(
    configurationData.primaryColor
  );
  const [secondaryColor, setSecondaryColor] = useState(
    configurationData.secondaryColor
  );
  const [imageS3, setImageS3] = useState(configurationData.imageS3);
  const [imageBase64, setBase64] = useState("");
  const [dialogueOpen, setDialogueOpen] = useState(false);
  const [code, setCode] = useState("");
  const [onLoading, setLoading] = useState(false);
  let customScript = document.createElement("script");
  customScript.type = "text/javascript";
  customScript.id = "previewpane";
  useEffect(() => {
    if (!showPreview) {
      let local = JSON.stringify({
        widgetName: widgetName,
        widgetSubtitle: widgetSubtitle,
        primaryColor: primaryColor,
        secondaryColor: secondaryColor,
        imageS3: imageS3,
        preview: showPreview,
        id: configurationData.id,
      });

      localStorage.setItem("configuration", local);
      var externalResource = document.getElementById("previewpane");
      if (externalResource) {
        externalResource.remove();
        location.reload();
      }
    }
  }, [widgetName, widgetSubtitle, primaryColor, secondaryColor, imageS3]);
  useEffect(() => {
    customScript.innerHTML = `
    (function (w, d, s, o, f, js, fjs) {
      w["JS-Widget"] = o;
      w[o] =
        w[o] ||
        function () {
          (w[o].q = w[o].q || []).push(arguments);
        };
      (js = d.createElement(s)), (fjs = d.getElementsByTagName(s)[0]);
      js.id = o;
      js.src = f;
      js.async = 1;
      fjs.parentNode.insertBefore(js, fjs);
    })(window, document, "script", "mw", "./././assets/widget.js");
    mw("init", {
      title: "${widgetName}",
      subtitle: "${widgetSubtitle}",
      orgCode: "1e4b31c747894cae93faa0c47d437fed",
      profileAvatar: "${imageS3}",
      color1: "${primaryColor}",
      color2: "${secondaryColor}",
    });`;

    if (showPreview) {
      document.body.appendChild(customScript);
      setShowPreview(false);
    }
  }, [showPreview]);

  let onImageSelect = async (e) => {
    setImageS3(URL.createObjectURL(e.target.files[0]));
    let file = e.target.files[0];
    const base64 = await convertBase64(file);
    setBase64(base64);
  };

  let convertBase64 = async (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const updateConfiguration = async () => {
    setLoading(true);
    let payload = {
      id: configurationData.id,
      chat_title: widgetName,
      chat_subtitle: widgetSubtitle,
      chat_photo_base64: imageBase64,
      chat_color1: primaryColor,
      chat_color2: secondaryColor,
      f_org: orgId,
    };
    let { success, org_code, chat_photo_s3 } = await fetch(
      `${config.REMOTE_COORD}/saveorgstyle`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    ).then((r) => r.json());
    if (success) {
      let blob = createCodeBlob(configurationData.id, org_code, chat_photo_s3);

      setCode(blob);
      setDialogueOpen(true);
      setLoading(false);
    }
  };

  const createCodeBlob = (id, org_code, chat_photo_s3) => {
    if (chat_photo_s3 == undefined)
      chat_photo_s3 =
        "https://chatwidget-cvxm23.s3.ap-south-1.amazonaws.com/a4be93995ceb4e08a869fa7f7d4eb798.png";
    const codeblob = `
<script type="text/javascript">;
(function (w,d,s,o,f,js,fjs) {
    w['JS-Widget'] = o;w[o] = w[o] || function () {(w[o].q = w[o].q || []).push(arguments)};
    js = d.createElement(s), fjs = d.getElementsByTagName(s)[0];
    js.id = o; js.src = f; js.async = 1; fjs.parentNode.insertBefore(js, fjs);
}(window, document, 'script', 'mw', 'https://chatwidget-cvxm23.netlify.app/widget.js'));
mw('init', {
    title: '${widgetName}',
    subtitle: '${widgetSubtitle}',
    profileAvatar: '${chat_photo_s3}',
    orgCode: '${org_code}',
    configurationID:${id},
    color1: '${primaryColor}',
    color2: '${secondaryColor}'
});
</script>`;
    return codeblob;
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(code);
    setDialogueOpen(false);
    alert("Copied to Clipboard");
    route("/edit-configuration");
  };
  return (
    <Container
      disableGutters
      maxWidth="xl"
      sx={{ height: "80vh", width: "48vw" }}
    >
      <CssBaseline />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
        }}
      >
        <Breadcrumbs aria-label="breadcrumb" mb={3}>
          <Link underline="hover" color="inherit" href="/">
            Home
          </Link>
          <Typography color="text.primary">Edit configuration</Typography>
        </Breadcrumbs>
        <Typography
          component="h1"
          variant="h5"
          mb={5}
          sx={{ fontSize: "2rem", fontWeight: "bold" }}
        >
          Edit Configurations
        </Typography>
        <Grid sx={{ flexGrow: 1 }} container spacing={2}>
          <Grid item>
            <Grid container spacing={2}>
              <Grid item paddingRight={2}>
                <Paper
                  sx={{
                    height: 450,
                    width: 450,
                  }}
                  variant="outlined"
                >
                  <Box
                    component="form"
                    sx={{
                      "& .MuiTextField-root": { m: 1, width: "35ch" },
                    }}
                    noValidate
                    padding={3}
                    autoComplete="off"
                  >
                    <div>
                      <TextField
                        required
                        id="outlined-basic"
                        label="Widget Title"
                        variant="outlined"
                        value={widgetName}
                        onChange={(e) => setWidgetName(e.target.value)}
                      />
                      <Divider />
                      <TextField
                        required
                        id="outlined-basic"
                        label="Subtitle"
                        variant="outlined"
                        value={widgetSubtitle}
                        onChange={(e) => setWidgetSubtitle(e.target.value)}
                      />
                      <Divider />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          padding: "1em",
                        }}
                      >
                        <label
                          htmlFor="color"
                          style={{
                            fontSize: "1.2rem",
                            marginRight: "2.1em",
                            marginTop: ".5em",
                          }}
                        >
                          Primary Color
                        </label>{" "}
                        <input
                          type="color"
                          id="color"
                          value={primaryColor}
                          style={{ height: "3em", width: "6.4em" }}
                          onChange={(e) => setPrimaryColor(e.target.value)}
                        />
                      </div>
                      <Divider />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          padding: "1em",
                        }}
                      >
                        <label
                          htmlFor="color"
                          style={{
                            fontSize: "1.2rem",
                            marginRight: "1em",
                            marginTop: ".5em",
                          }}
                        >
                          Secondary Color
                        </label>{" "}
                        <input
                          type="color"
                          id="color"
                          style={{ height: "3em", width: "6.4em" }}
                          value={secondaryColor}
                          onChange={(e) => setSecondaryColor(e.target.value)}
                        />
                      </div>
                      <Divider />

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          padding: "1em",
                        }}
                      >
                        <label
                          htmlFor="color"
                          style={{
                            fontSize: "1.2rem",
                            marginRight: "1em",
                            marginTop: ".5em",
                          }}
                        >
                          Logo
                        </label>{" "}
                        <Avatar
                          alt="bot logo"
                          src={imageS3}
                          style={{
                            marginRight: "1.4em",
                          }}
                        />
                      </div>

                      <Input
                        type="file"
                        id="avatar"
                        name="avatar"
                        accept="image/png, image/jpeg"
                        component="label"
                        size="small"
                        style={{ marginLeft: "1em" }}
                        onChange={(e) => {
                          onImageSelect(e);
                        }}
                      />
                    </div>
                  </Box>

                  <div className={style.configurationButton}>
                    <Button
                      variant="contained"
                      color="secondary"
                      disabled={widgetName ? false : true}
                      onClick={() => setShowPreview(true)}
                    >
                      Show Preview
                    </Button>
                  </div>
                  {onLoading ? (
                    <CircularProgress color="success" />
                  ) : (
                    <div className={style.configurationButton}>
                      <Button
                        variant="contained"
                        disabled={widgetName ? false : true}
                        onClick={() => updateConfiguration()}
                      >
                        Edit and generate code
                      </Button>
                    </div>
                  )}
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Dialog open={dialogueOpen} onClose={() => setDialogueOpen(false)}>
        <DialogTitle>Updated Successfully </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {code}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => copyToClipboard()}>
            Copy to Clipboard
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
