import React, { useEffect, useState } from "react";
import WorldMap from "react-svg-worldmap";
import config from "../../../config";
import LocaleTable from "./localetable";
import {
  Typography,
  CircularProgress,
  TextField,
  CssBaseline,
  Container,
  Avatar,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
export default function Locale() {
  const [locales, setLocales] = useState([]);
  const currentTime = moment();
  var halfMonth = moment().subtract(15, "days");
  const [startDate, setStartDate] = useState(halfMonth);
  const [endDate, setEndDate] = useState(currentTime);
  const [range, setRange] = useState("");

  useEffect(() => {
    getLocaleCount();
  }, [startDate, endDate]);

  const handleChange = (event) => {
    setRange(event.target.value);
    let d = moment().subtract(event.target.value, "day");
    setStartDate(d);
    let e = moment();
    setEndDate(e);
  };

  const getLocaleCount = async () => {
    let url = `${config.REMOTE_COORD}/analytics/graph/locales`;
    let configId = localStorage.getItem("configID");

    try {
      let data = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          configId: configId,
          startDate: startDate.unix(),
          endDate: endDate.unix(),
        }),
      });
      let { user_locales } = await data.json();
      setLocales(user_locales);
    } catch (error) {
      alert(error);
    }
  };

  return (
    <Container
      disableGutters
      maxWidth="xxl"
      sx={{ height: "100%", width: "92vw" }}
    >
      <CssBaseline />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            bgcolor: "background.paper",
            border: 1,
            borderColor: "grey.300",
          }}
          p={2}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "left",
            }}
          >
            <Avatar sx={{ bgcolor: "primary.main" }}>
              <FilterAltIcon />
            </Avatar>
            <Typography
              component="h1"
              variant="h5"
              color="text.secondary"
              ml={2}
              sx={{ fontSize: "2rem", fontWeight: "bold" }}
            >
              Filter
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "space-between",
            }}
          >
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label="Start Date"
                value={startDate}
                inputFormat="DD/MM/yyyy"
                onChange={(newValue) => {
                  setStartDate(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
              <Typography mt={2} ml={2} mr={2} sx={{ fontWeight: "thin" }}>
                to
              </Typography>
              <DatePicker
                label="End Date"
                value={endDate}
                inputFormat="DD/MM/yyyy"
                onChange={(newValue) => {
                  setEndDate(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
              <Box sx={{ width: 320, ml: 2 }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Custom Range
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={range}
                    label="daterange"
                    onChange={handleChange}
                  >
                    <MenuItem value={1}>1 Day</MenuItem>
                    <MenuItem value={7}>7 Day</MenuItem>
                    <MenuItem value={14}>14 Day</MenuItem>
                    <MenuItem value={30}>30 Day</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </LocalizationProvider>
          </Box>
        </Box>
        <Box
          sx={{
            marginTop: "2em",
            border: 1,
            borderColor: "grey.300",
          }}
        >
          <WorldMap
            color="#00448b"
            // title="Locales"
            value-suffix="count"
            size={800}
            data={locales}
            richInteraction
          />
        </Box>
        <Box
          sx={{
            marginTop: "2em",
            border: 1,
            borderColor: "grey.300",
          }}
        >
          <LocaleTable users={locales} />
        </Box>
      </Box>
    </Container>
  );
}
