import { Router } from "preact-router";
import { useState } from "react";
import Header from "./header";
import Dashboard from "./routes/dashboard";
import UsersTab from "./routes/dashboard/users";
import Configuration from "./routes/configuration";
import Login from "./routes/login";
import Forgotpass from "./routes/forgotpass";
import Verifyemail from "./routes/verifyemail";
import Register from "./routes/register";
import Joinorg from "./routes/joinorg";
import Resetpass from "./routes/resetpass";
import NewConfiguration from "./routes/configuration/newconfiguration";
import EditConfiguration from "./routes/configuration/editconfiguration";
import CreateOrg from "./routes/CreateOrg";
import Graph from "./routes/dashboard/graph";
import Locale from "./routes/dashboard/locale";

import { createTheme, ThemeProvider } from "@mui/material/styles";

function App() {
  const theme = createTheme();
  const [url, setUrl] = useState(" ");
  const handleRoute = (e) => {
    setUrl(e.url);
  };
  return (
    <ThemeProvider theme={theme}>
      <Header currentUrl={url} />
      <div
        style={{
          marginTop: "6em",
          width: "100%",
        }}
      >
        {window.localStorage.getItem("token") ? (
          <Router onChange={handleRoute}>
            <Dashboard path="/analytics" />
            <Configuration path="/" />
            <Login path="/login" />
            <Forgotpass path="/forgotpass" />
            <Resetpass path="/reset-password" />
            <Verifyemail path="/verifyemail" />
            <Register path="/register" />
            <Joinorg path="/joinorg" />
            <NewConfiguration path="/new-configuration" />
            <EditConfiguration path="/edit-configuration" />
            <CreateOrg path="/createorg" />
            <Graph path="/graph" />
            <Locale path="/graph/locales" />
            <UsersTab path="/users" />
          </Router>
        ) : (
          <div>
            <Router>
              <Login path="/" />
              <Resetpass path="/reset-password" />
              <Register path="/register" />
              <Forgotpass path="/forgotpass" />
              <Verifyemail path="/api/verify-email" />
              <Joinorg path="/joinorg" />
              <CreateOrg path="/createorg" />
            </Router>
          </div>
        )}
      </div>
    </ThemeProvider>
  );
}

export default App;
