import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Language, Timeline } from "@mui/icons-material";
import Locale from "./locale";
import Graph from "./insightgraph";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function UsersTab() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        bgcolor={"white"}
        sx={{ marginTop: "-2em", borderBottom: 1, borderColor: "grey.300" }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="tabs"
          sx={{ marginLeft: "3.5%" }}
        >
          <Tab
            icon={<Timeline />}
            iconPosition="start"
            label="Insights"
            disableRipple={true}
          />
          <Tab
            icon={<Language />}
            iconPosition="start"
            label="Locales"
            disableRipple={true}
            sx={{ marginLeft: "5%" }}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Graph />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Locale />
      </TabPanel>
    </Box>
  );
}
